$(function () {
    $('.filter__trigger').click(function () {
        var input = $(this).parent();

        $('.filter').not(input).removeClass('open');
        input.toggleClass('open');
    })

    let ignoreChange = false;
    $('.filter__checkbox').change(function () {
        if (!ignoreChange) {
            if ($(this).hasClass('filter__checkbox--group')) {
                ignoreChange = true;
                if ($(this).is(':checked')) {
                    $(this).closest('.filter__items').find('.filter__checkbox:not(.filter__checkbox--group)').prop('checked', true).change()
                } else {
                    $(this).closest('.filter__items').find('.filter__checkbox:not(.filter__checkbox--group)').removeAttr('checked').change()
                }
                ignoreChange = false;
            }

            applyOffreFilter();
        }
    })


})

function applyOffreFilter() {
    let offres = $('.offre')

    offres.show();

    let secteurs = [];
    $('.filter--secteur .filter__checkbox:checked:not(.filter__checkbox--group)').each(function () {
        secteurs.push(parseInt($(this).val()));
    })

    let regions = [];
    $('.filter--region .filter__checkbox:checked:not(.filter__checkbox--group)').each(function () {
        regions.push(parseInt($(this).val()));
    })

    let batiments = [];
    $('.filter--batiment .filter__checkbox:checked:not(.filter__checkbox--group)').each(function () {
        batiments.push(parseInt($(this).val()));
    })

    if (secteurs.length > 0) {
        $('.filter--secteur').addClass('active');
        offres.each(function () {
            let offre = $(this)
            if (!secteurs.includes(parseInt(offre.data('secteur')))) offre.hide();
        })
    }
    else {
        $('.filter--secteur').removeClass('active');
    }

    if (regions.length > 0) {
        $('.filter--region').addClass('active');
        offres.each(function () {
            let offre = $(this)
            if (!regions.includes(parseInt(offre.data('region')))) offre.hide();
        })
    }
    else {
        $('.filter--region').removeClass('active');
    }

    if (batiments.length > 0) {
        $('.filter--batiment').addClass('active');
        offres.each(function () {
            let offre = $(this)
            if (!batiments.includes(parseInt(offre.data('batiment')))) offre.hide();
        })
    }
    else {
        $('.filter--batiment').removeClass('active');
    }

    if ($('.offre:visible').length) {
        $('.offre--null').hide();
    }
    else {
        $('.offre--null').show();
    }
}
